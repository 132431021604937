.App {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  display: block;
  width: 100%;
  padding: 0 12%;

  @media screen and (max-width: 1430px) {
    padding: 0 6%;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 2%;
  }
}